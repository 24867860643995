import { IonContent } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";

import "./Unauthorize.scss";

const Unauthorize: React.FC = () => {
  const { t: translation } = useTranslation();
  return (
    <IonContent fullscreen>
      <div className="unauthorize-page">
        <div className="container">
          <div className="page-inner">
            <div className="unauthorize-box">
              <h3>{translation("404")}</h3>
              <h1>{translation("page_not_found!")}</h1>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </IonContent>
  );
};

export default Unauthorize;
