export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;

export const EventType = [
  { id: "nil", value: "No Action" },
  { id: "activity", value: "Activity" },
  { id: "sunexp", value: "Sun Exposure" },
  { id: "meal", value: "Meals" },
  { id: "bristol_chart", value: "Bristol Stool" },
];
export const weekDays: Array<string> = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const GENDER = [
  { id: "male", value: "Male" },
  { id: "female", value: "Female" },
];
export const METRIC = [
  { id: 0, value: "Imperial & Conventional (in/ft/lb)" },
  // { id: 1, value: "Metric & SI (cm/km/kg)" },
];
export const IMAGE_FILE_SIZE_LIMIT = 20; //  Mb
export const DEFAULT_LAST_LAT = 42.33;
export const DEFAULT_LAST_LONG = -83;
export const PAGE_RANGE_DISPLAYED = 5;

export const IMAGE_FILE_TYPES = [
  ".jpg",
  ".jpeg",
  ".png",
  ".jpg",
  ".JPEG",
  ".PNG",
];
export const MeasureType = [{ id: "g", value: "g" }];
export const INTENSITY = [
  { id: "resting", value: "Resting" },
  { id: "vlight", value: "Very Light" },
  { id: "light", value: "Light" },
  { id: "moderate", value: "Moderate" },
  { id: "heavy", value: "Heavy" },
];

export const DATE_FORMAT = "MM-DD-YYYY";
export const TIME_FORMAT = "hh:mm A";
export const MONTH_YEAR_FORMAT = "MMM YYYY";
export const LIST_RECORDS_LIMIT = 10;
export const ION_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSZ";

export const Page = {
  NEW_EVENTS: "new_event",
  NEW_SUBJECTIVE: "new_subjective",
  SUBJECTIVE_HISTORY: "subjective_history",
  NEW_ACTIVITY: "new_activity",
  NEW_MEAL: "new_meal",
  MEAL_HISTORY: "meal_history",
  NEW_RECIPE: "new_recipe",
  RECIPE_HISTORY: "recipe_history",
  EVENT_LIST: "event_list",
  MANAGE_EVENTS: "manage_events",
  MY_INFO: "my_info",
  PROFILE_PROGRESS: "profile_progress",
  ACTIVITY_HISTORY: "activity_history",
  NEW_LAB: "new_lab",
  LAB_HISTORY: "lab_history",
  NEW_MEASURE: "new_measure",
  MEASUREMENTS_HISTORY: "measurements_history",
};
export const BRISTOL_CHART_TYPE = {
  TYPE_1: "type1",
  TYPE_2: "type2",
  TYPE_3: "type3",
  TYPE_4: "type4",
  TYPE_5: "type5",
  TYPE_6: "type6",
  TYPE_7: "type7",
};

export const SUBJECTIVE_FIELDS = [
  {
    name: "mood",
    label: "Mood",
    ratings: [
      "Miserable",
      "Awful",
      "Bad",
      "Not the Best",
      "OK",
      "Alright",
      "Pretty Good",
      "Good",
      "Great",
      "Fantastic",
    ],
  },
  {
    name: "skin_quality",
    label: "Skin Quality ",
    ratings: [
      "Very Dry and Splotchy",
      "Dry and Poor Color",
      "Poor",
      "Could Be Better",
      "OK",
      "Not Bad",
      "Decent",
      "Nice",
      "Soft",
      "Very Soft and Even Color",
    ],
  },
  {
    name: "clothing_fit",
    label: "Clothing Fit",
    ratings: [
      "Busting Seams",
      "Very Bad Fit",
      "Bad Fit",
      "Poor Fit",
      "OK",
      "Decent Fit",
      "Nice Fit",
      "Good Fit",
      "Very Good Fit",
      "Perfect Fit",
    ],
  },
  {
    name: "hair_nail_quality",
    label: "Hair and Nail Quality",
    ratings: [
      "Very Brittle and Breaking",
      "Rough and Weak",
      "Poor",
      "Could Be Better",
      "OK",
      "Decent",
      "Nice",
      "Good",
      "Very Good",
      "Very Smooth and Strong",
    ],
  },
  {
    name: "recovery",
    label: "Recovery From Exercise",
    ratings: [
      "5.5 Days",
      "5 Days",
      "4.5 Days",
      "4 Days",
      "3.5 Days",
      "3 Days",
      "2.5 Days",
      "2 Days",
      "1.5 Days",
      "1 Day",
    ],
  },
  {
    name: "sleep_quality",
    label: "Sleep Quality ",
    ratings: [
      "Insomniac",
      "Constantly Waking",
      "Trouble Sleeping",
      "Hit or Miss",
      "OK",
      "Not Bad",
      "Decent Sleep",
      "Good Sleep",
      "Great Sleep",
      "Like the Dead",
    ],
  },
  // {
  //   name: "Bloating_Nausea",
  //   label: "Bloating/Nausea",
  //   ratings: [
  //     "No Interest",
  //     "Rarely Interested",
  //     "Interested Monthly",
  //     "Interested Bi-Weekly",
  //     "Interested Weekly",
  //     "Interested Every Few Days",
  //     "Interested Daily",
  //     "Interested Several Times a Day",
  //     "Interested Hourly",
  //     "Always Interested",
  //   ],
  // },
  {
    name: "energy_levels",
    label: "Energy Levels",
    ratings: [
      "Dead",
      "Exhausted",
      "Tired",
      "Somewhat Tired",
      "OK",
      "Decent",
      "Nice",
      "Good",
      "Great",
      "Boing!",
    ],
  },
  {
    name: "libido",
    label: "Libido",
    ratings: [
      "No Interest",
      "Rarely Interested",
      "Interested Monthly",
      "Interested Bi-Weekly",
      "Interested Weekly",
      "Interested Every Few Days",
      "Interested Daily",
      "Interested Several Times a Day",
      "Interested Hourly",
      "Always Interested",
    ],
  },
  {
    name: "hunger_cravings",
    label: "Hunger Cravings",
    ratings: [
      "Always Starving",
      "Hungry Hourly",
      "Craving Snacks Between Meals",
      "Occasional Snack Cravings",
      "OK Waiting for Meals",
      "Mostly Satiated",
      "No Snack Cravings",
      "No Meal Cravings",
      "Skipping Lunch",
      "What Food?",
    ],
  },
  {
    name: "body_heat",
    label: "Body Heat",
    ratings: [
      "Freezing",
      "Very Cold",
      "Cold",
      "Cool",
      "Normal",
      "Warm",
      "Very Warm",
      "Hot",
      "Very Hot",
      "Human Torch",
    ],
  },
  {
    name: "headaches",
    label: "Headaches",
    ratings: [
      "Constant",
      "Several Per Hour",
      "Hourly",
      "Daily",
      "Every Few Days",
      "Weekly",
      "Monthly",
      "Rarely",
      "Long Time Ago",
      "None",
    ],
  },
  {
    name: "bloating_nausea",
    label: "Bloating/Nausea",
    ratings: [
      "Am I Pregnant?",
      "Unbearable",
      "Constant",
      "Always for Hours After Eating",
      "Always After Eating",
      "After Eating Certain Foods",
      "Once Meal a Day",
      "Once in a While",
      "Maybe a Little",
      "None",
    ],
  },
];

export const SUBJECTIVE_INITIAL = {
  mood: 5,
  skin_quality: 5,
  clothing_fit: 5,
  hair_nail_quality: 5,
  sleep_quality: 5,
  Bloating_Nausea: 5,
  recovery: 5,
  energy_levels: 5,
  libido: 5,
  hunger_cravings: 5,
  body_heat: 5,
  headaches: 5,
  bloating_nausea: 5,
  linkedTo: undefined,
};

export const GENDER_TYPE = {
  MALE: "male",
  FEMALE: "female",
};

export const TIME = {
  SUNUP: "sunup",
  SUNSET: "sunset",
  SUND: "sund",
  SUNDE: "sunde",
};

export const LAB_FIELDS = [
  {
    identifier: "t3",
    name: "Free T3",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "pgmlToPmol",
  },
  {
    identifier: "t4",
    name: "Free T4",
    metric: "pmol/L",
    standard: "ng/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "tsh",
    name: "TSH",
    metric: "uIU/mL",
    standard: "uIU/mL",
    conversion: null,
  },
  {
    identifier: "rt3",
    name: "Reverse T3",
    metric: "pmol/L",
    standard: "ng/mL",
    conversion: "ngmlToPmol",
  },
  {
    identifier: "amsc",
    name: "Salivary Cortisol (AM)",
    metric: "nmol/L",
    standard: "ug/dL",
    conversion: "ugdlToNmol",
  },
  {
    identifier: "pmsc",
    name: "Salivary Cortisol (PM)",
    metric: "nmol/L",
    standard: "ug/dL",
    conversion: "ugdlToNmol",
  },
  {
    identifier: "melatonin_a",
    name: "Melatonin (AM)",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "ngmlToPmol",
  },
  {
    identifier: "melatonin_p",
    name: "Melatonin (PM)",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "ngmlToPmol",
  },
  {
    identifier: "hdl",
    name: "HDL Level",
    metric: "mmol/L",
    standard: "mg/dL",
    conversion: "mgdlToMmol",
  },
  {
    identifier: "bpsys",
    name: "Blood Pressure Systolic",
    metric: "mmHg",
    standard: "mmHg",
    conversion: null,
  },
  {
    identifier: "bpdia",
    name: "Blood Pressure Diastolic ",
    metric: "mmHg",
    standard: "mmHg",
    conversion: null,
  },
  {
    identifier: "sf",
    name: "Serum Ferritin",
    metric: "pmol/L",
    standard: "ng/mL",
    conversion: "ngmlToPmol",
  },
  {
    identifier: "progesterone",
    name: "Progesterone",
    metric: "pmol/L",
    standard: "ng/mL",
    conversion: "ngmlToPmol",
  },
  {
    identifier: "estradiol",
    name: "Estradiol",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "pgmlToPmol",
  },
  {
    identifier: "dhea_s",
    name: "DHEA-S",
    metric: "pmol/L",
    standard: "ug/dL",
    conversion: "ugdlToPmol",
  },
  {
    identifier: "f_testosterone",
    name: "Free Testosterone",
    metric: "nmol/L",
    standard: "ng/dL",
    conversion: "ngdlToNmol",
  },
  {
    identifier: "vd3",
    name: "Vitamin D3",
    metric: "nmol/L",
    standard: "ng/mL",
    conversion: "ngdlToNmol",
  },
  {
    identifier: "vb12",
    name: "Vitamin B12",
    metric: "pmol/L",
    standard: "pg/mL",
    conversion: "pgmlToPmol",
  },
  {
    identifier: "folate",
    name: "Folate",
    metric: "nmol/L",
    standard: "ng/mL",
    conversion: "ngmlToNmol",
  },
  {
    identifier: "potassium",
    name: "Potassium",
    metric: "mmol/L",
    standard: "mmol/L",
    conversion: null,
  },
  {
    identifier: "ferritin",
    name: "Ferritin",
    metric: "nmol/L",
    standard: "ng/mL",
    conversion: "ngmlToNmol",
  },
  {
    identifier: "homocystine",
    name: "Homocystine",
    metric: "pmol/L",
    standard: "ng/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "hscrp",
    name: "HSCRP",
    metric: "nmol/L",
    standard: "mg/L",
    conversion: "mglToNmol",
  },
  {
    identifier: "mmp9",
    name: "MMP9",
    metric: "pmol/L",
    standard: "ng/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "cil_1b",
    name: "Cytokine IL-1 Beta",
    metric: "pmol/L",
    standard: "ng/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "cil_4",
    name: "Cytokine IL-4",
    metric: "pmol/L",
    standard: "ng/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "cil_8",
    name: "Cytokine IL-8",
    metric: "pmol/L",
    standard: "ng/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "cil_12",
    name: "Cytokine IL-12",
    metric: "pmol/L",
    standard: "ng/dL",
    conversion: "ngdlToPmol",
  },
  {
    identifier: "heliobacter",
    name: "Heliobacter",
    metric: "detected",
    standard: "detected",
    conversion: null,
  },
  {
    identifier: "candida",
    name: "Candida",
    metric: "detected",
    standard: "detected",
    conversion: null,
  },
];

export const MEASURE_FIELDS = [
  {
    type: "Type 1",
    svg_type: BRISTOL_CHART_TYPE.TYPE_1,
    Type_name: "Separate hard lumps",
    name: "type1",
  },
  {
    type: "Type 2",
    Type_name: "Sausage-shaped,but lumpy ",
    svg_type: BRISTOL_CHART_TYPE.TYPE_2,
    name: "type2",
  },
  {
    type: "Type 3",
    Type_name: "Sausage-shaped,cracked surface ",
    svg_type: BRISTOL_CHART_TYPE.TYPE_3,
    name: "type3",
  },
  {
    type: "Type 4",
    Type_name: "Smooth and soft",
    svg_type: BRISTOL_CHART_TYPE.TYPE_4,
    name: "type4",
  },
  {
    type: "Type 5",
    Type_name: "Soft blob with clear cut edges",
    svg_type: BRISTOL_CHART_TYPE.TYPE_5,
    name: "type5",
  },
  {
    type: "Type 6",
    Type_name: "Fluffy pieces with raged edges",
    svg_type: BRISTOL_CHART_TYPE.TYPE_6,
    name: "type6",
  },
  {
    type: "Type 7",
    Type_name: "Entirely liquid",
    svg_type: BRISTOL_CHART_TYPE.TYPE_7,
    name: "type7",
  },
];

export const Toggle: Array<string> = [
  "email_toggle",
  "sms_toggle",
  "web_notification_toggle",
];

export const ACTIVITY_DATA = [
  {
    name: "resting",
    description: "resting_description",
  },
  {
    name: "very_light",
    description: "very_light_description",
  },
  {
    name: "light",
    description: "light_description",
  },
  {
    name: "moderate",
    description: "moderate_description",
  },
  {
    name: "heavy",
    description: "heavy_description",
  },
];
