import endpoint from "../constants/endPoints";
import { IFCMToken } from "../interfaces/DashboardInterface";
import { ISunExp } from "../interfaces/SunExpInterface";
import ApiResponse from "../resources/entity/IApiResponse";
import * as http from "../utils/http";

/**
 * @description:- getDashboardData api for  getting dashboard circle functionality
 * @returns
 */
export const getEventData = (): Promise<ApiResponse> => {
  return http.get(endpoint.user.EVENT_DATA);
};
/**
 * @description:- getFillData api for  getting dashboard circle functionality
 * @returns
 */

export const getFillData = (): Promise<ApiResponse> => {
  return http.get(endpoint.user.FILL_DATA);
};
/**
 * add new sun exposure
 */
export const addNewSunExp = (data: ISunExp): Promise<ApiResponse> =>
  http.post(endpoint.user.SUN_EXP, data);

export const getFCMToken = (data: IFCMToken) => {
  return http.post(endpoint.user.sendFcmToken, { ...data });
};
