import { IonContent, IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";

import { IFood, IFoodData, ISaveMeal } from "../../interfaces/MealInterface";

interface IProps {
  data: IFoodData[] | IFoodData | ISaveMeal | any;
}

const RecipeComposition = (props: IProps) => {
  const { t: translation } = useTranslation();

  return (
    <IonContent className="ion-padding">
      <div className="modal-body">
        <div>
          <p className="white opacity-05">
            {translation("keywords")}:
            <br />
            {props?.data?.meta
              ? props?.data?.meta.keywords
              : props?.data[0]?.meta
              ? props?.data[0]?.meta.keywords
              : props?.data?.keywords
              ? props?.data?.keywords
              : props?.data[0]?.keywords}
          </p>
        </div>
        <div className="form-group">
          <IonLabel>{translation("macro_table")}</IonLabel>
          <div className="theme-table">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <td>{translation("macro")}</td>
                    <td>{translation("amount")}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translation("protein")}</td>
                    <td>
                      {props?.data?.meta
                        ? parseInt(
                            props?.data[0]?.meta.protein
                              ? props?.data[0]?.meta.protein
                              : props?.data?.meta.protein
                          ).toFixed(2)
                        : parseInt(
                            props.data[0]?.protein
                              ? props.data[0]?.protein
                              : props.data?.protein
                          ).toFixed(2)}
                      g
                    </td>
                  </tr>
                  <tr>
                    <td>{translation("fat")}</td>
                    <td>
                      {props.data?.meta
                        ? parseInt(
                            props.data[0]?.meta.fat
                              ? props.data[0]?.meta.fat
                              : props.data?.meta.fat
                          ).toFixed(2)
                        : parseInt(
                            props.data[0]?.fat
                              ? props.data[0]?.fat
                              : props.data?.fat
                          ).toFixed(2)}
                      g
                    </td>
                  </tr>
                  <tr>
                    <td>{translation("carbohydrates")}</td>
                    <td>
                      {props.data?.meta
                        ? parseInt(
                            props.data[0]?.meta.carbs
                              ? props.data[0]?.meta.carbs
                              : props.data?.meta.carbs
                          ).toFixed(2)
                        : parseInt(
                            props.data[0]?.carbs
                              ? props.data[0]?.carbs
                              : props.data?.carbs
                          ).toFixed(2)}
                      g
                    </td>
                  </tr>
                  <tr>
                    <td>{translation("kcal")}</td>
                    <td>
                      {props.data?.meta
                        ? parseInt(
                            props.data[0]?.meta.cals
                              ? props.data[0]?.meta.cals
                              : props.data?.meta.cals
                          ).toFixed(2)
                        : parseInt(
                            props.data[0]?.cals
                              ? props.data[0]?.cals
                              : props.data?.cals
                          ).toFixed(2)}
                      g
                    </td>
                  </tr>
                  <tr>
                    <td>{translation("fibre")}</td>
                    <td>
                      {props.data?.meta
                        ? parseInt(
                            props.data[0]?.meta.fiber
                              ? props.data[0]?.meta.fiber
                              : props.data?.meta.fiber
                          ).toFixed(2)
                        : parseInt(
                            props.data[0]?.fiber
                              ? props.data[0]?.fiber
                              : props.data?.fiber
                          ).toFixed(2)}
                      g
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="form-group">
          <IonLabel>{translation("household_measure")}</IonLabel>
          <div className="theme-table">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <td>{translation("amount")}</td>
                    <td>{translation("measure")}</td>
                    <td>{translation("weight")}</td>
                  </tr>
                </thead>
                <tbody>
                  {props.data?.food_weights?.length ? (
                    props.data?.food_weights?.map(
                      (householdData: IFood, index: number) => (
                        <tr key={index}>
                          <td>{householdData?.amount}</td>
                          <td>{householdData?.weight_description}</td>
                          <td>{parseInt(householdData?.weight).toFixed(2)}g</td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td>
                        {props.data[0]?.amount
                          ? props.data[0]?.amount
                          : props.data?.amount}
                      </td>
                      <td>
                        {props.data[0]?.meta?.household_unit
                          ? props.data[0]?.meta?.household_unit
                          : props.data?.meta?.household_unit}
                      </td>
                      <td>
                        {props.data[0]?.weight
                          ? props.data[0]?.weight
                          : props.data?.weight}{" "}
                        g
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  );
};
export default RecipeComposition;
