import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
} from "@ionic/react";
import "./components/i18n/I18n";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import { Suspense, useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";

// eslint-disable-next-line import/no-extraneous-dependencies
import { getToken } from "@firebase/messaging";
import Menu from "./components/Menu";
import PublicRoutes from "./routes/PublicRoutes";

import { IUserInterface } from "./interfaces/CommonInterface";
import { getFCMToken } from "./services/dashboard";
import { firebaseIntialize, isFirebaseSupported } from "./firebase";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./assets/styles/App.scss";

setupIonicReact();

const App: React.FC = () => {
  const authData: IUserInterface = useSelector(
    (state: RootStateOrAny) => state.AuthReducer.authData
  );

  useEffect(() => {
    requestPermission();
  }, [authData.email]);

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {      
      getTokenn();
    } else if (permission === "denied") {
      // alert("you denied for the notification")
    }
  }

  const sendFcmToken = async (fcmToken: string) => {
    if(!window.location.href.includes("login"))
    getFCMToken({ token: fcmToken });
  };

  const getTokenn = async () => {
    isFirebaseSupported()
      ? getToken(firebaseIntialize(), {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        })
          .then((currentToken) => {
            if (currentToken) {
              sendFcmToken(currentToken);
              // Send the token to your server and update the UI if necessary
              // ...
            } else {
              // Show permission request UI
              console.log(
                "No registration token available. Request permission to generate one."
              );
              // ...
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // ...
          })
      : null;
  };

  return (
    <IonApp>
      <Suspense fallback={null}>
        <IonReactRouter>
          <IonSplitPane contentId="main" when="false">
            {authData?.id ? <Menu authData={authData} /> : null}

            <IonRouterOutlet id="main">
              <Route path="/" component={PublicRoutes} />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </Suspense>
    </IonApp>
  );
};

export default App;
