import { closeOutline } from "ionicons/icons";
import Pagination from "react-js-pagination";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonSpinner,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import CommonButton from "../../components/commonButton/CommonButton";
import ConfirmModal from "../../components/commonModal/ConfirmModal";

import toast from "../../utils/toastsMessage";
import "./Meals.scss";
import { PAGE_RANGE_DISPLAYED } from "../../constants/commonConstants";
import RecipeComposition from "./RecipeComposition";
import { deleteRecipe, getRecipeHistory } from "../../services/recipes";
import {
  IRecipeFoodData,
  IRecipeHistory,
} from "../../interfaces/RecipeInterface";

const RecipeHistory = () => {
  const { t: translation } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openFoodModal, setOpenFoodModal] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [recipeData, setrecipeData] = useState<IRecipeHistory[]>([]);
  const [recipeData1, setrecipeData1] = useState<IRecipeHistory>();
  const [recipeData2, setrecipeData2] = useState<IRecipeFoodData[]>([]);
  const [lastPage, setLastPage] = useState<number>(0);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const { reset } = useForm();

  /**
   * Get getRecipeList
   */

  const getRecipeList = async (e: number) => {
    setDataLoading(true);
    const recipeRes = await getRecipeHistory(e);

    if (recipeRes?.data?.history?.data.length > 0) {
      setLastPage(recipeRes?.data?.history?.last_page);
      reset(recipeRes?.data?.meals);
      setPageNo(e);
    }
    const meals = recipeRes?.data?.history.data;
    setrecipeData(meals);
    setDataLoading(false);
  };

  const selectMeal = (meal: IRecipeHistory) => {
    setOpen(true);
    setrecipeData1(meal);
  };

  const selectFood = (food: IRecipeFoodData[]) => {
    setOpenFoodModal(true);
    setrecipeData2(food);
  };

  /**
   * Delete Recipe
   */
  const deleteRecipeId = async (data: number) => {
    const response = await deleteRecipe({ meal: data });
    if (response && response?.data && response?.data?.success === true) {
      toast.success(translation("recipe_deleted_successfully"));
    }
    getRecipeList(pageNo);
    setOpen(false);
    if (response && response?.data && response?.data?.success === false) {
      toast.error(translation(`${response?.data?.errors}`));
    }
  };

  useEffect(() => {
    getRecipeList(1);
  }, []);

  return (
    <>
      <div className="theme-card">
        <div className="card-mid">
          <div className="theme-table">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <td>{translation("name")}</td>
                    <td>{translation("date")}</td>
                  </tr>
                </thead>
                <tbody>
                  {recipeData?.length ? (
                    recipeData?.map((meals) => (
                      <tr onClick={() => selectMeal(meals)}>
                        <td>{meals.title}</td>
                        <td>{meals.created_at}</td>
                      </tr>
                    ))
                  ) : dataLoading ? (
                    <tr>
                      <td colSpan={2}>
                        <div className="text-center">
                          <IonSpinner name="lines-sharp" />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan={2}>
                        <div className="text-center table-info">
                          <p>{translation("no_data")}</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="common-pagination">
            { recipeData?.length ? <Pagination
              activePage={pageNo as unknown as number}
              itemsCountPerPage={10}
              totalItemsCount={lastPage as number}
              pageRangeDisplayed={PAGE_RANGE_DISPLAYED}
              onChange={(e) => {
                if (pageNo !== e && !dataLoading)
                  getRecipeList(e as unknown as number);
              }}
            /> : null}
          </div>
        </div>
      </div>
      {/* Edit modal start */}
      <IonModal
        isOpen={open}
        trigger="open-modal"
        onWillDismiss={() => setOpen(false)}
        className="theme-modal"
      >
        <IonHeader>
          <div className="heading">
            <h2>{translation("edit")}</h2>
          </div>
          <div className="modal-closer">
            <IonButton onClick={() => setOpen(false)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </div>
        </IonHeader>
        <IonContent className="ion-padding">
          <div className="modal-body">
            <div className="form-group mb-0">
              <IonLabel>{translation("meal_composition")}</IonLabel>
            </div>
            <div className="theme-table">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <td>{translation("food")}</td>
                      <td>{translation("amount")}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {recipeData1?.foods.length ? (
                      recipeData1?.foods.map((item: any) => (
                        <tr onClick={() => selectFood(item)}>
                          <td>{item[0].name}</td>
                          <td>{item[1]}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={2}>
                          <div className="text-center table-info">
                            <p>{translation("no_data")}</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="modal-bottom">
            <CommonButton
              type="button"
              parentClassName="danger-btn"
              name="Delete"
              onClick={async () => {
                await setConfirmModal(false);
                setConfirmModal(true);
              }}
            />
          </div>
        </IonContent>
      </IonModal>
      {/* Edit modal end */}

      {/* Food modal start */}
      <IonModal
        isOpen={openFoodModal}
        trigger="open-modal"
        onWillDismiss={() => setOpenFoodModal(false)}
        className="theme-modal"
      >
        <IonHeader>
          <div className="heading">
            <h2>{recipeData2[0]?.name}</h2>
          </div>
          <div className="modal-closer">
            <IonButton onClick={() => setOpenFoodModal(false)}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </div>
        </IonHeader>

        <RecipeComposition data={recipeData2[0]} />
      </IonModal>

      {/* Confirm deletion of the user's created recipe. */}
      <ConfirmModal
        isOpen={confirmModal ? confirmModal : false}
        confirmText={`${translation("are_you_sure_you_want_to_delete")} ${
          recipeData1?.title
        }?`}
        cancelText={translation("no")}
        submitText={translation("yes")}
        onClose={() => setConfirmModal(false)}
        onSubmit={() => deleteRecipeId(recipeData1?.id as unknown as number)}
      />
      {/* Food modal end */}
    </>
  );
};

export default RecipeHistory;
