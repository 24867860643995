import { closeOutline, addOutline } from "ionicons/icons";
import {
  IonModal,
  IonHeader,
  IonButton,
  IonIcon,
  IonContent,
  IonLabel,
  IonSpinner,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import ConfirmModal from "../../components/commonModal/ConfirmModal";
import CommonTextBox from "../../components/commonTextbox/CommonTextBox";

import {
  IFoodData,
  IMealHistory,
  IMeasureDropdown,
} from "../../interfaces/MealInterface";
import useDebounce from "../meals/UseDebounce";

interface IProps {
  deleteMealsId(arg0: number): void;
  setMealSearchText: (param: string) => void;
  setConfirmModal: (param: boolean) => void;
  open: boolean | undefined;
  confirmModal: boolean | undefined;
  setOpen(arg1: boolean): void;
  searchMealsData: IMealHistory[];
  selectYourMeal(item: IMealHistory): void;
  addFood(item: IMealHistory): void;
  searchResultsData: IMealHistory[];
  selectFood(resultData: IFoodData): void;
  selectMeal(resultData: IMeasureDropdown): void;
  dataLoading: boolean;
  deleteFood: (data: IMealHistory) => void;
  deleteMeal: IMealHistory | undefined;
}

const SearchRecipe = (props: IProps) => {
  const { t: translation } = useTranslation();
  const { control } = useForm({});
  const [isData, setIsData] = useState<string>("");

  const [search, setSearch] = useState<string | null>(null);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    async function fetchData() {
      props.setMealSearchText(search as string);
    }

    if (debouncedSearch) {
      fetchData();
    }
  }, [debouncedSearch]);

  return (
    <IonModal
      isOpen={props.open}
      trigger="open-modal"
      onWillDismiss={() => props.setOpen(false)}
      className="theme-modal"
    >
      <IonHeader>
        <div className="heading">
          <h2>{translation("search")}</h2>
        </div>
        <div className="modal-closer">
          <IonButton onClick={() => props.setOpen(false)}>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </div>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="modal-body">
          <CommonTextBox
            fieldType="input"
            control={control}
            type="text"
            className="form-control"
            placeHolder="Type to Search"
            name="text"
            onChange={(e) => {
              setSearch(e.detail.value as string);
              setIsData(e.detail.value as string);
            }}
          />
          <div className="form-group">
            <IonLabel>{translation("your_meals")}</IonLabel>
            <div className="theme-table">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <td>{translation("meal_name")}</td>
                      <td>{translation("total_kcal")}</td>
                      <td>{null}</td>
                    </tr>
                  </thead>
                  {props.dataLoading ? (
                    <div className="text-center">
                      <IonSpinner name="lines-sharp" />
                    </div>
                  ) : (
                    ""
                  )}
                  <tbody>
                    {props.searchMealsData?.length && isData && search ? (
                      props.searchMealsData.map((item: IMealHistory) => (
                        <tr>
                          <td>{item.title}</td>
                          <td onClick={() => props.selectYourMeal(item)}>
                            {" "}
                            {item?.total_calories}
                          </td>
                          <td align="center">
                            <IonButton
                              type="button"
                              className="theme-button success-btn"
                              onClick={() => props.addFood(item)}
                            >
                              <IonIcon icon={addOutline} />
                            </IonButton>
                            <IonButton
                              type="button"
                              className="theme-button danger-btn"
                              onClick={() => props.deleteFood(item)}
                            >
                              <IonIcon icon={closeOutline} />
                            </IonButton>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>
                          <div className="text-center table-info">
                            <p>{translation("no_result")}</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="form-group">
            <IonLabel>{translation("search_result")}</IonLabel>
            <div className="theme-table">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <td>{translation("food")}</td>
                      <td>{translation("keywords")}</td>
                      <td>{null}</td>
                    </tr>
                  </thead>
                  {props.dataLoading && search ? (
                    <div className="text-center">
                      <IonSpinner name="lines-sharp" />
                    </div>
                  ) : (
                    ""
                  )}
                  <tbody>
                    {props.searchResultsData?.length && isData ? (
                      props.searchResultsData.map((resultData) => (
                        <tr>
                          <td
                            onClick={() =>
                              props.selectFood(
                                resultData as unknown as IFoodData
                              )
                            }
                          >
                            {" "}
                            {resultData.name}
                          </td>
                          <td>
                            {resultData.keywords.length > 25
                              ? `${resultData.keywords.slice(0, 25)}...`
                              : resultData.keywords}
                          </td>
                          <td align="center">
                            <IonButton
                              type="button"
                              className="theme-button success-btn"
                              onClick={() => props.selectMeal(resultData)}
                            >
                              <IonIcon icon={addOutline} />
                            </IonButton>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3}>
                          <div className="text-center table-info">
                            <p>{translation("no_result")}</p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* Confirm deletion of the user's created recipe. */}
        <ConfirmModal
          isOpen={props.confirmModal ? props.confirmModal : false}
          confirmText={`${translation("are_you_sure_you_want_to_delete")} ${
            props.deleteMeal?.title
          }?`}
          cancelText={translation("no")}
          submitText={translation("yes")}
          onClose={() => props.setConfirmModal(false)}
          onSubmit={() =>
            props.deleteMealsId(props.deleteMeal?.food_id as unknown as number)
          }
        />
      </IonContent>
    </IonModal>
  );
};

export default SearchRecipe;
