import ActionType from "../../resources/enums";
import Storage from "../../utils/storage";

import { IProfile } from "../../interfaces/ProfileInterface";

export interface IAuthReducerState {
  isLoggedIn: boolean;
  authData: IProfile;
}

const authData = Storage.get("authData")
  ? JSON.parse(Storage.get("authData") as string)
  : {};

const initialState: IAuthReducerState = {
  isLoggedIn: false,
  authData,
};
export declare type ReduxAction = {
  type: string;
  payload?: IProfile;
};

const AuthReducer = (state = initialState, action: ReduxAction) => {
  switch (action.type) {
    case ActionType.LOGIN: {
      return {
        ...state,
        isLoggedIn: true,
        authData: action?.payload,
      };
    }
    case ActionType.LOGOUT:
      localStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        authData: {},
      };
    case ActionType.UPDATE_AUTH:
      return {
        ...state,
        authData: {
          ...state.authData,
          birthdate: action?.payload?.birthdate,
          gender: action?.payload?.gender,
          height: action?.payload?.height,
          firstSignIn_app: action?.payload?.firstSignIn_app,
          metric: action?.payload?.metric,
          weight: action?.payload?.weight,
          last_lat: action?.payload?.last_lat,
          last_long: action?.payload?.last_long,
          timezone: action?.payload?.timezone,
          push_token: action?.payload?.push_token,
          phone: action?.payload?.phone,
          name: action?.payload?.name,
        },
      };
    case ActionType.NOTIFICATION_COUNT:
      return {
        ...state,
        authData: {
          ...state.authData,
          notification_count: action?.payload,
        },
      };
    default:
      return state;
  }
};

export default AuthReducer;
