import { FC } from "react";

import "./Loader.scss";

const Loader: FC = () => {
  return (
    <div className="loading-main">
      <div className="loadingio-spinner-dual-ball-hchn3d2m5bs">
        <div className="ldio-fr7iv3pd6t">
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};
export default Loader;
