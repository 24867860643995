import { addOutline, closeOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import AddRecipe from "./AddRecipe";
import AddUserRecipe from "./AddUserRecipe";
import AddUserRecipeDetail from "./AddUserRecipeDetail";
import CommonButton from "../../components/commonButton/CommonButton";
import CommonTextBox from "../../components/commonTextbox/CommonTextBox";
import CustomRecipe from "./CustomRecipe";
import { deleteMealsRecipe, searchMeals } from "../../services/meal";
import RecipeDetail from "./RecipeDetail";
import RecipeComposition from "./RecipeComposition";
import SearchRecipe from "./SearchRecipe";
import { addNewRecipes } from "../../services/recipes";
import toast from "../../utils/toastsMessage";

import "./Meals.scss";

import { addUserMealValidationSchema } from "../../validationSchemas/mealsValidation";
import { IDropdown } from "../../interfaces/CommonInterface";
import ConfirmModal from "../../components/commonModal/ConfirmModal";
import { IManageEvents } from "../../interfaces/EventInterface";
import {
  IMeasureDropdown,
  IRecipeFoodData,
  IRecipeHistory,
  IRecipeId,
  IRecipeMeta,
  ISaveRecipe,
} from "../../interfaces/RecipeInterface";

interface IProps {
  linkedTo?: number;
  setNameData(arg: string): void;
  nameData: string;
  saveSearchData: ISaveRecipe[];
  setSaveSearchData(arg: ISaveRecipe[]): void;
  updateEvent?: (args: IManageEvents) => void;
  data?: IManageEvents;
  fromDashboard?: boolean;
  // setConfirmModal?(args: boolean): void;
  recipesList: ISaveRecipe[][];
  setRecipesList(args: any): void;
  restrictedError: { key: number | undefined; value: string }[];
  setRestrictedError: React.Dispatch<
    React.SetStateAction<{ key: number | undefined; value: string }[]>
  >;
}

const NewRecipe = (props: IProps) => {
  const { t: translation } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [restrictOpen, setRestrictOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [foodError, setFoodError] = useState<boolean>(false);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openYourMeals, setOpenYourMeals] = useState<boolean>(false);
  const [openMealDetail, setOpenMealDetail] = useState<boolean>(false);
  const [openUserMealDetail, setOpenUserMealDetail] = useState<boolean>(false);
  const [openSearchMealDetail, setOpenSearchMealDetail] =
    useState<boolean>(false);
  const [saveSearchMealDetail, setSaveSearchMealDetail] =
    useState<boolean>(false);
  const [cancelRestrictedRecipe, setcancelRestrictedRecipe] =
    useState<boolean>(false);
  const [cancelRestrictedYoursRecipe, setcancelRestrictedYoursRecipe] =
    useState<boolean>(false);
  const [openAddMeal, setOpenAddMeal] = useState<boolean>(false);
  const [openCreateMeal, setOpenCreateMeal] = useState<boolean>(false);
  const [cancelFoodItems, setCancelFoodItems] = useState<boolean>(false);

  const [selectedRecipeName, setSelectedRecipeName] = useState<string>("");
  const [searchMealsData, setSearchMealsData] = useState<IRecipeHistory[]>([]);
  const [searchMealsDataDeatail, setSearchMealsDataDeatail] = useState<
    IRecipeFoodData[]
  >([]);
  const [searchResultsData, setSearchResultsData] = useState<IRecipeHistory[]>(
    []
  );
  const [searchFoodData, setSearchFoodData] = useState<IRecipeFoodData>();
  const [showRecipesIndex, setShowRecipesIndex] = useState<number>();
  const [deleteMeal, setDeleteMeal] = useState<IRecipeHistory>();
  const [saveSearchDetailsData, setSaveSearchDetailsData] =
    useState<ISaveRecipe>();
  const [selectMealData, setSelectMealData] = useState<
    IMeasureDropdown | any
  >();
  const [selectYourMealData, setSelectYourMealData] = useState<
    IRecipeHistory[]
  >([]);
  const [measureList, setMeasureList] = useState<IDropdown[]>([]);
  const [mealSearchText, setMealSearchText] = useState<string>("");
  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const [restrictedMealData, setRestrictedMealData] = useState<IRecipeMeta>();
  const [isRestricted, setIsRestricted] = useState<boolean | undefined>(false);
  const [yourMealsData, setYourMealsData] = useState<IRecipeHistory>();
  const [recipeKey, setrecipeKey] = useState<number>();
  const [yoursRecipeKey, setYoursRecipeKey] = useState<number>();
  const [yourRecipeFoodId, setYourRecipeFoodId] = useState<IRecipeId[]>([]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addUserMealValidationSchema(translation)),
  });

  /**
   * mealsSearch entered by user
   *
   */

  const mealsSearch = async (data: string) => {
    if (data) {
      setDataLoading(true);
      const mealRes = await searchMeals(data);
      const meals = mealRes?.data?.meals;
      const mealsResult = mealRes?.data?.results;
      setSearchMealsData(meals);
      setSearchResultsData(mealsResult);
      setDataLoading(false);
    }
  };

  /**
   * cancelfood item added by user
   *
   */
  const cancelFoodItem = () => {
    props.setRecipesList([
      ...props.recipesList.filter(
        (item: ISaveRecipe[], index: number) => index !== deleteIndex
      ),
    ]);
    props.setSaveSearchData([
      ...props.saveSearchData.filter(
        (data1: ISaveRecipe, index: number) => index !== deleteIndex
      ),
    ]);

    setCancelFoodItems(false);

    // cancel restricted stock recipe
    if (cancelRestrictedRecipe) {
      props.setRestrictedError((prevArray) =>
        prevArray.filter((item) => item.key !== recipeKey)
      );
      setcancelRestrictedRecipe(false);
    }
    // cancel restricted yours meal
    else if (cancelRestrictedYoursRecipe) {
      props.setRestrictedError((prevArray) =>
        prevArray.filter((item) => item.key !== yoursRecipeKey)
      );
      setcancelRestrictedYoursRecipe(false);
    }
  };

  useEffect(() => {
    mealsSearch(mealSearchText);
  }, [mealSearchText, props.recipesList]);

  useEffect(() => {
    if (props.nameData?.trim()) setData();
  }, [props.nameData]);

  const setData = () => {
    props.setNameData(props.nameData);
  };

  /**
   *  selected food data in search
   */
  const selectFood = (meal: IRecipeFoodData) => {
    setOpenMealDetail(true);
    setSearchFoodData(meal);
  };

  const selectMeal = (meal: IMeasureDropdown) => {
    setOpenSearchMealDetail(true);
    const measures = meal.food_weights.map((w) => ({
      id: w.id,
      value: `${w.weight_description} (${w.amount} / serving)`,
    }));
    setMeasureList([...measures]);

    setSelectMealData(meal);
  };

  const selectYourMeal = (meals: IRecipeHistory) => {
    setOpenYourMeals(true);
    setSelectYourMealData(meals.foods);
  };
  const addFood = (data: IRecipeHistory) => {
    setOpenAddMeal(true);
    setSelectYourMealData(data.foods);
    setIsRestricted(data.is_restricted);
    setSelectedRecipeName(data.title);
    setYourMealsData(data);
    setYourRecipeFoodId([
      ...yourRecipeFoodId,
      { id: data.food_id, value: data.title },
    ]);
  };

  const selectSearchMeal = (data: ISaveRecipe) => {
    setSaveSearchMealDetail(true);
    setSaveSearchDetailsData(data);
  };
  const selectedYourMealsDeatail = (data: IRecipeFoodData[]) => {
    setOpenUserMealDetail(true);
    setSearchMealsDataDeatail(data);
  };

  /**
   * Handle reset button functionality
   *
   */

  /**
   * Handle onsubmit form submission
   */
  const onSubmit = async (data: IRecipeMeta) => {
    const info = selectMealData.food_weights.filter(
      (i: IRecipeFoodData) => i.id?.toString() === data.measure?.toString()
    );
    const { weight } = info[0];
    const w = weight * (data.amount as number);
    const mod = w / 100;
    const tempArr = [];
    const newMeal = {
      recipe_name: null,
      amount: data.amount,
      measure: info[0].weight_description,
      name: props.nameData,
      weight: w,
      meta: {
        carbs: selectMealData.carbs * mod,
        fat: selectMealData.fat * mod,
        fiber: selectMealData.fiber * mod,
        cals: selectMealData.cals * mod,
        household_unit: info[0].weight_description,
        isCustom: false,
        keywords: selectMealData.keywords,
        name: selectMealData.name,
        ndb_no: selectMealData.ndb_no,
        protein: selectMealData.protein * mod,
      },
      is_restricted: selectMealData.is_restricted,
    };
    props.setSaveSearchData([...props.saveSearchData, newMeal]);
    tempArr.push([null, [newMeal]]);
    props.setRecipesList([...props.recipesList, ...tempArr]);
    toast.success(translation("meals_copied_successfully"));
    setOpenSearchMealDetail(false);
    // reset({
    //   amount: "",
    //   measure: "",
    // });
  };

  /**
   * Handle onsubmit form submission
   */

  const onUserMeal = async (data: IRecipeMeta) => {
    setOpenAddMeal(false);
    const amount: number = data.amount as number;
    const tempArr = [];
    const recipeName = selectedRecipeName;
    const newMeal: ISaveRecipe[] = selectYourMealData.map((meal: any) => {
      const weight = meal[0].food_weights[0];
      const w = weight.weight * (meal[1] * amount);
      const mod = w / 100;
      return {
        recipe_name: recipeName,
        amount: meal[1] * amount,
        weight: w,
        measure: weight.weight_description,
        name: props.nameData,
        meta: {
          isCustom: false,
          ndb_no: meal[0].ndb_no,
          name: meal[0].name,
          keywords: meal[0].keywords,
          cals: meal[0].cals * mod,
          fat: meal[0].fat * mod,
          protein: meal[0].protein * mod,
          carbs: meal[0].carbs * mod,
          fiber: meal[0].fiber * mod,
          household_unit: weight.weight_description,
        },
        is_restricted: meal[0].is_restricted,
      };
    });
    reset({ amout: "" });
    tempArr.push([recipeName, newMeal]);
    props.setRecipesList([...props.recipesList, ...tempArr]);

    props.setSaveSearchData([...props.saveSearchData, ...newMeal]);

    toast.success(translation("meals_copied_successfully"));
  };

  /**
   * Handle custom meals
   */
  const onCreateSubmit = async (data: IRecipeMeta) => {
    const tempArr = [];
    const newMeal = {
      recipe_name: null,
      amount: data.amount,
      measure: data.measure,
      weight: data.weight,
      meta: {
        carbs: data.carbs,
        fat: data?.fat,
        cals: data.cals,
        fiber: data.fiber,
        household_unit: data.household_unit,
        isCustom: true,
        keywords: data.keywords,
        name: data.name,
        protein: data.protein,
      },
      is_restricted: data.is_restricted,
    };
    tempArr.push([null, [newMeal]]);
    props.setRecipesList([...props.recipesList, ...tempArr]);
    props.setSaveSearchData([...props.saveSearchData, { ...newMeal }]);
    setOpenCreateMeal(false);
  };

  /**
   * Handle addRecipe form submission
   */
  const addRecipe = async () => {
    setFoodError(false);
    setError(false);
    if (!props.nameData) {
      setError(true);
      return;
    }
    if (!props.saveSearchData?.length) {
      setFoodError(true);
      return;
    }
    setSaveLoading(true);

    const response = await addNewRecipes({
      items: props.saveSearchData,
      name: props.nameData,
      linkedTo: props.linkedTo ? props.linkedTo : null,
    });

    setError(false);
    if (response && response?.data?.success === true) {
      if (props?.fromDashboard && props.updateEvent && props.data) {
        await props.updateEvent(props.data);
      }
      reset({});
      toast.success(translation("recipe_added_successfully"));
      props.setRestrictedError([]);
      props.setRecipesList([]);
      props.setSaveSearchData([]);
      props.setNameData("");
    }

    setSaveLoading(false);

    if (response && response?.data && response?.data?.success === false) {
      if (typeof response?.data?.errors === "object") {
        let tempStr = "";
        Object.values(response?.data?.errors as string[][]).map(
          (i: string[]) => {
            tempStr += ` ${i[0]}`;
            return;
          }
        );
        toast.error(translation(tempStr));
      } else {
        toast.error(translation(response?.data?.errors));
      }
    }
  };
  const deleteFood = (data: IRecipeHistory) => {
    setConfirmModal(true);
    setDeleteMeal(data);
  };

  /**
   *  meals delete
   * @param data number
   */

  const deleteMealsId = async (data: number) => {
    setConfirmModal(true);

    const response = await deleteMealsRecipe({ meal: data });
    if (response && response?.data && response?.data?.success === true) {
      toast.success(translation("meals_deleted_successfully"));
    }
    mealsSearch(mealSearchText);

    setConfirmModal(false);

    if (response && response?.data && response?.data?.success === false) {
      toast.error(translation(`${response?.data?.errors}`));
    }
  };

  const resetOnClick = () => {
    setValue("names", "");
  };

  const checkRestricted = (data: IRecipeMeta) => {
    if (isRestricted) {
      setValue("amount", data.amount);
      setRestrictedMealData(data);
      setRestrictOpen(true);
    } else {
      onUserMeal(data as IRecipeMeta);
    }
  };

  return (
    <>
      <div className="theme-card">
        <div className="card-mid">
          <div className="form-error">
            <CommonTextBox
              label="Recipe Name"
              fieldType="input"
              control={control}
              type="text"
              className="form-control"
              placeHolder="Spaghetti & Meatballs"
              name="names"
              disabled={props.saveSearchData?.length > 0 ? false : true}
              value={props.nameData}
              onChange={(e) => props.setNameData(e.detail.value as string)}
            />
            {!error ? null : (
              <div className="message error">
                <p> {translation("name_required")}</p>
              </div>
            )}

            {props.restrictedError?.length
              ? props.restrictedError.map((data, key) => (
                  <p className="dy-error" key={key}>
                    {data.value}
                  </p>
                ))
              : null}
          </div>
          <div className="theme-table mt20">
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <td>{translation("food")}</td>
                    <td>{translation("serving")}</td>
                    <td>{null}</td>
                  </tr>
                </thead>

                <tbody>
                  {props.recipesList?.length
                    ? props.recipesList.map((item: any, index) => (
                        <>
                          {item[0] ? (
                            <tr key={index}>
                              <td
                                onClick={() => {
                                  setShowRecipesIndex(
                                    showRecipesIndex === undefined ||
                                      showRecipesIndex !== index
                                      ? index
                                      : undefined
                                  );
                                }}
                              >
                                {item[0]}
                              </td>
                              <td>-</td>
                              <td align="center">
                                <IonButton
                                  type="button"
                                  className="theme-button danger-btn"
                                  onClick={() => {
                                    if (item[1][0].is_restricted) {
                                      setcancelRestrictedYoursRecipe(true);
                                      const Foodid = yourRecipeFoodId.filter(
                                        (i: IRecipeId) => i.value === item[0]
                                      );
                                      setYoursRecipeKey(Foodid[0].id);
                                    }
                                    setDeleteIndex(index);
                                    setCancelFoodItems(!cancelFoodItems);
                                  }}
                                >
                                  <IonIcon icon={closeOutline} />
                                </IonButton>
                              </td>
                            </tr>
                          ) : null}
                          {(showRecipesIndex === index || !item[0]) &&
                            item[1]?.map((data: ISaveRecipe, key: number) => (
                              <tr key={key + 1}>
                                <td onClick={() => selectSearchMeal(data)}>
                                  {data?.meta?.name}
                                </td>
                                <td onClick={() => selectSearchMeal(data)}>
                                  {data?.amount}
                                </td>
                                {!item[0] ? (
                                  <td align="center">
                                    <IonButton
                                      type="button"
                                      className="theme-button danger-btn"
                                      onClick={() => {
                                        if (data.is_restricted) {
                                          setcancelRestrictedRecipe(true);
                                          setrecipeKey(data?.meta?.ndb_no);
                                        }
                                        setDeleteIndex(index);
                                        setCancelFoodItems(!cancelFoodItems);
                                      }}
                                    >
                                      <IonIcon icon={closeOutline} />
                                    </IonButton>
                                  </td>
                                ) : null}
                              </tr>
                            ))}
                        </>
                      ))
                    : null}
                </tbody>

                <tbody>
                  <tr>
                    <td colSpan={3}>
                      <div className="text-center table-info">
                        <h3>{translation("add_some_food_to_get_started")}</h3>
                        <p>{translation("hint_press_button")}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {foodError ? (
                <div className="message error">
                  <p> {translation("food_required")}</p>
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <IonGrid>
              <IonRow>
                <IonCol size="8" size-md="6">
                  <div>
                    <CommonButton
                      type="button"
                      parentClassName="primary-btn"
                      disabled={saveLoading}
                      onClick={() => addRecipe()}
                    >
                      {translation("save")}

                      {saveLoading ? <IonSpinner name="dots" /> : null}
                    </CommonButton>
                    <CommonButton
                      type="button"
                      parentClassName="grey-btn"
                      name="Reset"
                      onClick={() => {
                        resetOnClick();
                        props.setSaveSearchData([]);
                        props.setRecipesList([]);
                        props.setRestrictedError([]);
                        props.setNameData("");
                      }}
                    />
                  </div>
                </IonCol>
                <IonCol size="4" size-md="6">
                  <div className="drop-btn-right">
                    {/* Custom-drop start */}
                    <div className="custom-drop">
                      <IonButton
                        type="button"
                        onClick={() => setOpenMenu(!openMenu)}
                        className="theme-button primary-btn"
                      >
                        <IonIcon icon={addOutline} />
                      </IonButton>
                      {openMenu ? (
                        <div
                          className="drop-list"
                          onClick={() => setOpenMenu(false)}
                        >
                          <span onClick={() => setOpen(true)}>
                            {translation("search_foods")}
                          </span>
                          <span onClick={() => setOpenCreateMeal(true)}>
                            {translation("create_custom")}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    {/* Custom-drop end */}
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </div>
      {/* Search modal start */}
      <SearchRecipe
        open={open}
        deleteFood={deleteFood}
        confirmModal={confirmModal}
        dataLoading={dataLoading}
        setOpen={setOpen}
        setMealSearchText={setMealSearchText}
        searchMealsData={searchMealsData}
        selectYourMeal={selectYourMeal}
        addFood={addFood}
        searchResultsData={searchResultsData}
        selectFood={selectFood}
        selectMeal={selectMeal}
        deleteMealsId={deleteMealsId}
        setConfirmModal={setConfirmModal}
        deleteMeal={deleteMeal}
      />
      {/* Search modal end */}
      {/* Your Meals modal start */}
      <AddUserRecipe
        selectedYourMealsDeatail={selectedYourMealsDeatail}
        openYourMeals={openYourMeals}
        setOpenYourMeals={setOpenYourMeals}
        selectYourMealData={selectYourMealData}
      />
      {/* Meal detail modal start */}
      <AddUserRecipeDetail
        openMealDetail={openMealDetail}
        setOpenMealDetail={setOpenMealDetail}
        searchFoodData={searchFoodData}
      />
      {/* Create Meal modal start */}
      <CustomRecipe
        openCreateMeal={openCreateMeal}
        setOpenCreateMeal={setOpenCreateMeal}
        onCreateSubmit={onCreateSubmit}
        linkedTo={props.linkedTo}
      />
      {/* Add Meal modal start */}
      <AddRecipe
        openSearchMealDetail={openSearchMealDetail}
        setOpenSearchMealDetail={setOpenSearchMealDetail}
        onSubmit={onSubmit}
        measureList={measureList}
        selectMealData={selectMealData}
        setRestrictedError={props.setRestrictedError}
        restrictedError={props.restrictedError}
      />
      {/* Meal detail modal start */}
      <RecipeDetail
        openUserMealDetail={openUserMealDetail}
        setOpenUserMealDetail={setOpenUserMealDetail}
        searchMealsDataDeatail={searchMealsDataDeatail}
      />
      {/* Add Meal modal start */}
      <>
        <IonModal
          isOpen={openAddMeal}
          trigger="open-modal"
          onWillDismiss={() => setOpenAddMeal(false)}
          className="theme-modal"
        >
          <IonHeader>
            <div className="heading">
              <h2>{translation("add_meal")}</h2>
            </div>
            <div className="modal-closer">
              <IonButton onClick={() => setOpenAddMeal(false)}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </div>
          </IonHeader>
          <IonContent className="ion-padding">
            <form
              onSubmit={handleSubmit((data) => {
                setRestrictOpen(false);
                checkRestricted(data as IRecipeMeta);
              })}
              noValidate
            >
              <div className="modal-body">
                <div className="theme-table">
                  <div className="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <td>{translation("food")}</td>
                          <td>{translation("amount")}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {selectYourMealData.map((item: any) => (
                          <tr>
                            <td onClick={() => selectedYourMealsDeatail(item)}>
                              {item[0]?.name}
                            </td>
                            <td>{item[1]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <CommonTextBox
                  label="Amount of Recipe Consumed"
                  fieldType="input"
                  control={control}
                  type="number"
                  className="form-control"
                  placeHolder="0"
                  name="amount"
                  error={errors.amount}
                />
                <CommonButton type="submit" parentClassName="primary-btn">
                  {translation("save")}
                </CommonButton>
              </div>
            </form>
          </IonContent>
        </IonModal>
        <IonModal
          isOpen={saveSearchMealDetail}
          trigger="open-modal"
          onWillDismiss={() => setSaveSearchMealDetail(false)}
          className="theme-modal"
        >
          <IonHeader>
            <div className="heading">
              <h2> {saveSearchDetailsData?.meta?.name}</h2>
            </div>
            <div className="modal-closer">
              <IonButton onClick={() => setSaveSearchMealDetail(false)}>
                <IonIcon icon={closeOutline} />
              </IonButton>
            </div>
          </IonHeader>

          <RecipeComposition data={saveSearchDetailsData} />
        </IonModal>
      </>
      {/* Add Meal modal end */}

      {/* confirmModal for cancel food  item */}
      <ConfirmModal
        isOpen={cancelFoodItems ? cancelFoodItems : false}
        confirmText={`${translation("are_you_sure_you_want_to_delete")} ?`}
        cancelText={translation("no")}
        submitText={translation("yes")}
        onClose={() => setCancelFoodItems(false)}
        onSubmit={() => {
          cancelFoodItem();
        }}
      />

      {/* confirmModal for restricted food */}
      <ConfirmModal
        isOpen={restrictOpen}
        confirmText={`${translation(
          "are_you_sure_you_want_to_add_restricted_food"
        )} ?`}
        cancelText={translation("no")}
        submitText={translation("yes")}
        onClose={() => {
          setRestrictOpen(false);
        }}
        onSubmit={() => {
          reset({});
          const tempArray = [...props.restrictedError];
          yourMealsData?.validation_text.map((item: string) =>
            tempArray.push({ key: yourMealsData.food_id, value: item })
          );
          props.setRestrictedError([...tempArray]);
          onUserMeal(restrictedMealData as IRecipeMeta);
        }}
      />
    </>
  );
};

export default NewRecipe;
