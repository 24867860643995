import endpoint from "../constants/endPoints";
import { ISaveMeal } from "../interfaces/MealInterface";
import ApiResponse from "../resources/entity/IApiResponse";
import * as http from "../utils/http";

/**
 * @description:- getMealHistory api for  getting dashboard circle functionality
 * @returns
 */
export const getMealHistory = (PageNo: number): Promise<ApiResponse> => {
  return http.get(
    endpoint.meals.GET_MEALS_HISTORY.replace(":pageNo", `${PageNo}`)
  );
};

/**
 * deleteMeals request
 */
export const deleteMeals = (id: { meal: number }): Promise<ApiResponse> =>
  http.post(endpoint.meals.DELETE_MEAL_HISTORY, id);
/**
 * deleteMeals recipe
 */
export const deleteMealsRecipe = (id: { meal: number }): Promise<ApiResponse> =>
  http.post(endpoint.meals.DELETE_MEAL_RECIPE, id);

/**
 * searchMeals request
 */
export const searchMeals = (searchText: string): Promise<ApiResponse> => {
  return http.get(endpoint.meals.SEARCH_MEALS.replace(`:query`, searchText));
};

/**
 * updateEvents request
 */
export const addNewMeals = (data: {
  items: ISaveMeal[];
  name: string;
  linkedTo?: number | null;
}): Promise<ApiResponse> => http.post(endpoint.meals.ADD_NEW_MEALS, data);
