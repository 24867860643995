import * as yup from "yup";
import { TFunction } from "react-i18next";
import moment from "moment";

// Validation at the time of sun exposure
const addSunExpValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    start: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("time"),
      })
    ),
    end: yup
      .string()
      .required(
        translation("empty_field_error", {
          field_label: translation("time_to"),
        })
      )
      .test(
        "is-greater",
        `${translation("end_time_should_be_greater_than")}`,
        function (value) {
          const { start } = this.parent;
          return moment(value).isAfter(moment(start));
        }
      ),
  });

export default addSunExpValidationSchema;
