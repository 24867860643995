import moment, { Duration, Moment } from "moment";
import SunCalc from "suncalc";
import {
  DEFAULT_LAST_LAT,
  DEFAULT_LAST_LONG,
} from "../constants/commonConstants";
import { IEventList } from "../interfaces/EventInterface";
import { IProfile } from "../interfaces/ProfileInterface";
import { ISunPosition } from "../interfaces/SunExpInterface";
import { IProfileTime } from "../interfaces/UserInterface";
import { IAuthReducerState } from "../redux/reducers/AuthReducer";

class Helper {
  renderSunTimes = (info: IProfile) => {
    let last_lat;
    let last_long;
    let defaulted;

    // 15 will work if not 30 angleInDegrees
    SunCalc.addTime(
      /* Number */ 15,
      /* morningName */ "vitdstart",
      /* eveningName */ "vitdend"
    );

    if (!info?.last_lat || !info?.last_long) {
      last_lat = DEFAULT_LAST_LAT;
      last_long = DEFAULT_LAST_LONG;
      defaulted = true;
    } else {
      last_lat = info.last_lat;
      last_long = info.last_long;
      defaulted = false;
    }
    const times = SunCalc.getTimes(
      new Date(),
      last_lat as number,
      last_long as number
    );
    return { ...times, defaulted };
  };

  /**
   * Valid Date
   */
  valid = (date: Moment) => {
    return date instanceof moment && !Number.isNaN(date as unknown as number);
  };

  /**
   * Parse Times
   */
  parseTimes = (str: string, profile2?: IProfile | undefined) => {
    const suntimes: IProfileTime = this.renderSunTimes(profile2 as IProfile);
    const tokens: { [key: string]: Date | undefined } = {
      sunup: suntimes?.sunrise,
      sund: suntimes?.vitdstart,
      sunset: suntimes?.sunset,
      sunde: suntimes?.vitdend,
    };
    if (tokens[str]) return moment(tokens[str]).format("h:mm a");
    return moment(str, "hh:mm").format("h:mm a");
  };

  SunCalc = (profile: IProfile) => {
    const Time = this.renderSunTimes(profile);
    return { ...Time };
  };

  /**
   * Get Index Keys
   */

  getIndexKey = (time: Date, keys: number[]) => {
    const timestamp = moment(time, "h:mm a").valueOf();
    let increment = 0;

    while (true) {
      if (keys.indexOf(timestamp + increment) !== -1) increment += 1;
      else break;
    }
    return timestamp + increment;
  };

  /**
   * Get Sorted  to get time in order
   */
  getSorted = (e: any) => {
    let indexing_keys: number[] = [];
    const events: any = {};
    e
      ? Object?.keys(e)?.forEach((val: any) => {
          let event: any = e[val];
          const rest = event.custom || event.stock;

          event = {
            ...event,
            ...rest,
            id: event.id,
          };

          event.time = this.parseTimes(event.time);
          if (!this.valid(moment(event.time, "hh:mm a"))) return;
          if (event.time_to !== null)
            event.time_to = this.parseTimes(event.time_to);
          if (!this.valid(moment(event.time_to, "hh:mm a")))
            event.time_to = null;
          const indexing_key = this.getIndexKey(event.time, indexing_keys);
          indexing_keys.push(indexing_key);
          events[indexing_key] = event;
        })
      : null;
    indexing_keys = indexing_keys.sort((a: number, b: number) => a - b);
    const temp: IEventList[] = [];
    indexing_keys.forEach((key: any) => {
      temp.push(events[key]);
    });
    return temp;
  };

  /**
   *  google jwt
   */
  parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          // eslint-disable-next-line
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const data = JSON.parse(jsonPayload);
    return data;
  };

  /**
   * Get getIuTotal  Add SUN EXPOSURE
   */
  getIuTotal = (
    startTime: Date,
    endTime: Date,
    userData: IAuthReducerState,
    amount: number
  ) => {
    const start = moment(startTime, "hh:mm a");
    const end = moment(endTime, "hh:mm a");
    let length = moment.duration(start.diff(end)) as Duration;
    length = length.asMinutes() as any;

    const last_lat = userData.authData.last_lat || DEFAULT_LAST_LAT;
    const last_long = userData.authData.last_long || DEFAULT_LAST_LONG;
    const suntimes = SunCalc.getTimes(new Date(), last_lat, last_long);

    const sunpos_start = SunCalc.getPosition(
      start as unknown as Date,
      last_lat,
      last_long
    );

    const sunpos_end = SunCalc.getPosition(
      end as unknown as Date,
      last_lat,
      last_long
    );
    const sunpos_solarnoon = SunCalc.getPosition(
      suntimes.solarNoon,
      last_lat,
      last_long
    );

    let iutotal;
    let iurate_start;
    let iurate_end;
    const min_alt = 30 * (Math.PI / 180);

    if (
      (sunpos_start.altitude as ISunPosition) > min_alt ||
      (sunpos_end.altitude as ISunPosition) > min_alt
    ) {
      if (
        (suntimes.solarNoon as ISunPosition) > sunpos_start &&
        (suntimes.solarNoon as ISunPosition) < sunpos_end
      ) {
        iurate_start = -201.48 + 7.64 * sunpos_start.altitude * (180 / Math.PI);
        const iurate_solarnoon =
          -201.48 + 7.64 * sunpos_solarnoon.altitude * (180 / Math.PI);
        iurate_end = -201.48 + 7.64 * sunpos_end.altitude * (180 / Math.PI);
        const length1 =
          (suntimes.solarNoon as unknown as number) -
          (start as unknown as number);
        const length2 =
          (end as unknown as number) -
          (suntimes.solarNoon as unknown as number);
        const iutotals =
          (((Math.abs(iurate_start - iurate_solarnoon) * length1) / 2 +
            Math.min(iurate_start, iurate_solarnoon) *
              (length as unknown as number)) *
            amount) /
          100;
        const iutotale =
          (((Math.abs(iurate_solarnoon - iurate_end) * length2) / 2 +
            Math.min(iurate_solarnoon, iurate_end) *
              (length as unknown as number)) *
            amount) /
          100;
        iutotal = iutotals + iutotale;
      } else {
        iurate_start = -201.48 + 7.64 * sunpos_start.altitude * (180 / Math.PI);
        iurate_end = -201.48 + 7.64 * sunpos_end.altitude * (180 / Math.PI);
        iutotal =
          (((Math.abs(iurate_start - iurate_end) *
            (length as unknown as number)) /
            2 +
            Math.min(iurate_start, iurate_end) *
              (length as unknown as number)) *
            amount) /
          100;
      }
    } else {
      iutotal = 0;
    }

    return Math.round(iutotal);
  };

  getLastFourWeekNumbers = () => {
    const weekNumbers = [];
    const currentDate = new Date();

    for (let i = 0; i < 4; i += 1) {
      const weekNumber = this.getWeekNumber(currentDate);
      weekNumbers.push(weekNumber);
      currentDate.setDate(currentDate.getDate() - 7);
    }
    return weekNumbers;
  };

  getWeekNumber = (date: Date) => {
    const target: any = new Date(date.valueOf());
    const dayNr = (date.getDay() + 6) % 7;
    target.setDate(target.getDate() - dayNr + 3);
    const firstThursday = target.valueOf();
    target.setMonth(0, 1);
    if (target.getDay() !== 4) {
      target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - target) / 604800000);
  };
}
export default new Helper();
