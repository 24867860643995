import { closeOutline } from "ionicons/icons";
import { IonButton, IonHeader, IonIcon, IonModal } from "@ionic/react";

import RecipeComposition from "./RecipeComposition";

import { IFoodData } from "../../interfaces/MealInterface";

interface IProps {
  openUserMealDetail: boolean | undefined;
  setOpenUserMealDetail(arg0: boolean): void;
  searchMealsDataDeatail: IFoodData[];
}

const RecipeDetail = (props: IProps) => {
  return (
    <IonModal
      isOpen={props.openUserMealDetail}
      trigger="open-modal"
      onWillDismiss={() => props.setOpenUserMealDetail(false)}
      className="theme-modal"
    >
      <IonHeader>
        <div className="heading">
          <h2> {props.searchMealsDataDeatail[0]?.keywords}</h2>
        </div>
        <div className="modal-closer">
          <IonButton onClick={() => props.setOpenUserMealDetail(false)}>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </div>
      </IonHeader>

      <RecipeComposition data={props.searchMealsDataDeatail[0]} />
    </IonModal>
  );
};
export default RecipeDetail;
