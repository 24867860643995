import React, { KeyboardEvent } from "react";
import { TextFieldTypes, InputChangeEventDetail } from "@ionic/core";
import { IonInput } from "@ionic/react";

interface IProps {
  type?: TextFieldTypes;
  label?: string;
  className: string;
  placeholder?: string;
  value?: string | number;
  disabled?: boolean;
  readonly?: boolean;
  maxlength?: number;
  max?: string;
  onBlur?: (e: CustomEvent<FocusEvent>) => void;
  onChange: (event: CustomEvent<InputChangeEventDetail>) => void;
  onKeyDown?: (event: CustomEvent<KeyboardEvent>) => void;
}

const CommonInputBox: React.FC<IProps> = (props) => {
  const {
    type,
    className,
    placeholder,
    value,
    label,
    disabled = false,
    readonly = false,
    onBlur,
    onChange,
    onKeyDown,
    maxlength,
    max,
  } = props;

  return (
    <IonInput
      ion-label={label}
      type={type}
      disabled={disabled}
      readonly={readonly}
      className={className}
      placeholder={placeholder}
      maxlength={maxlength}
      max={max}
      min={0}
      value={value}
      onIonBlur={onBlur}
      onIonChange={(e) => onChange(e as CustomEvent<InputChangeEventDetail>)}
      onKeyDown={(e) =>
        onKeyDown && onKeyDown(e as unknown as CustomEvent<KeyboardEvent>)
      }
      autocomplete="off"
      autocorrect="off"
    />
  );
};

export default React.memo(CommonInputBox);
