import * as yup from "yup";
import { TFunction } from "react-i18next";

// Validation at the customMealValidationSchema
const customMealValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    name: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("name"),
      })
    ),
    keywords: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("keywords"),
      })
    ),
    measure: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("measure"),
      })
    ),
    amount: yup.number().required(
      translation("empty_field_error", {
        field_label: translation("amount"),
      })
    ),
    weight: yup.number().required(
      translation("empty_field_error", {
        field_label: translation("weight"),
      })
    ),

    household_unit: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("household_unit"),
      })
    ),
    cals: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("cals"),
      })
    ),
    fat: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("fat"),
      })
    ),
    carbs: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("carbs"),
      })
    ),
    protein: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("protein"),
      })
    ),
    fiber: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("fibre"),
      })
    ),
  });
const addMealValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    measure: yup.string().required(
      translation("empty_field_error", {
        field_label: translation("measure"),
      })
    ),
    amount: yup
      .number()
      .min(1)
      .required(
        translation("empty_field_error", {
          field_label: translation("amount"),
        })
      )
      .typeError("Amount must be a number"),
  });
const addUserMealValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    amount: yup
      .number()
      .typeError(
        translation("empty_field_error", {
          field_label: translation("amount"),
        })
      )
      .min(
        0.125,
        translation("greater_then_value", {
          value: 0.125,
        })
      )
      .required(
        translation("empty_field_error", {
          field_label: translation("amount"),
        })
      ),
  });
export {
  customMealValidationSchema,
  addMealValidationSchema,
  addUserMealValidationSchema,
};
