import endpoint from "../constants/endPoints";
import ApiResponse from "../resources/entity/IApiResponse";
import * as http from "../utils/http";

/**
 * Login request
 */
export const login = (email: string, password: string): Promise<ApiResponse> =>
  http.post(endpoint.auth.LOGIN, { email, password });

/**
 * @description:- get user profile
 * @returns
 */
export const getProfile = (): Promise<ApiResponse> => {
  return http.get(endpoint.user.GET_PROFILE);
};
