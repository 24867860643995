import { useDispatch, useSelector } from "react-redux";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useHistory } from "react-router";
import { notificationsOutline } from "ionicons/icons";
import { useEffect, useState } from "react";

import { IAuthReducerState } from "../../redux/reducers/AuthReducer";
import { notificationCount } from "../../redux/actions/AuthAction";
import { RootState } from "../../redux/reducers/Index";
import ROUTES from "../../constants/routes";
import { getProfile } from "../../services/auth";

import "./Header.scss";

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isHelpPage, setIsHelpPage] = useState(false);
  const authData: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );

  async function updateCount() {
    const profileRes = await getProfile();
    dispatch(notificationCount(profileRes?.data?.data?.notification_count));
  }

  useEffect(() => {
    if (
      window.location.pathname === ROUTES.HELP &&
      authData?.authData?.firstSignIn_app
    ) {
      setIsHelpPage(true);
    } else {
      setIsHelpPage(false);
    }
  }, [window.location.pathname]);

  const HeaderSideCrumb = (text: string) => {
    switch (text) {
      case ROUTES.DASHBOARD: {
        return "Dashboard";
      }
      case ROUTES.EVENTS: {
        return "Events";
      }
      case ROUTES.PROFILE: {
        return "Profile";
      }
      case ROUTES.MEALS: {
        return "Meal History";
      }
      case ROUTES.RECIPE: {
        return "Recipes";
      }
      case ROUTES.ACTIVITIES: {
        return "Activities";
      }
      case ROUTES.LABS: {
        return "Labs";
      }
      case ROUTES.MEASUREMENTS: {
        return "Bristol Stool Chart";
      }
      case ROUTES.NOTIFICATION: {
        return "Notifications";
      }
      case ROUTES.SUBJECTIVE: {
        return "Subjective";
      }
      case ROUTES.HELP: {
        return "Help";
      }

      default: {
        return "Gut Master";
      }
    }
  };

  return (
    <IonHeader>
      <IonToolbar>
        <div className="container-fluid">
          <IonGrid>
            <IonRow>
              <IonCol size="6">
                <div className="header-left">
                  <IonButtons>
                    <IonMenuButton disabled={isHelpPage} />
                  </IonButtons>
                  <IonTitle>
                    {HeaderSideCrumb(window.location.pathname)}
                  </IonTitle>
                </div>
              </IonCol>
              <IonCol size="6">
                {!authData?.authData?.firstSignIn_app ? (
                  <div className="header-right">
                    <IonButton
                      type="button"
                      className="theme-button grey-btn notification-button"
                      disabled={isHelpPage}
                      onClick={() => {
                        updateCount();
                        history.push(ROUTES.NOTIFICATION);
                      }}
                    >
                      <IonIcon icon={notificationsOutline} />
                      <span>
                        {((authData?.authData?.notification_count as number) >
                        99
                          ? "99+"
                          : authData?.authData?.notification_count) ?? 0}
                      </span>
                    </IonButton>
                  </div>
                ) : null}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
