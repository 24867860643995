import cogoToast, { CTOptions } from "cogo-toast";
import { ReactNode } from "react";

const toast = {
  success: (message: string) => {
    const options: Record<string, unknown> = {
      heading: "Success",
    };
    cogoToast.success(message, options);
  },

  error: (message: string) => {
    const options: Record<string, unknown> = {
      position: "top-right",
      heading: "Error",
    };
    cogoToast.error(message, options);
  },

  info: (message: string | ReactNode, options?: CTOptions) => {
    options = {
      ...options,
    };

    cogoToast.info(message, options);
  },
  warn: (message: string | ReactNode, options?: CTOptions) => {
    options = {
      ...options,
      position: "top-right",
      heading: "Alert",
    };

    cogoToast.warn(message, options);
  },
};

export default toast;
