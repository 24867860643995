/* eslint-disable react/jsx-no-useless-fragment */

import { IonPage } from "@ionic/react";

import { FC, lazy, Suspense } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import PrivateGuard from "./ProtectedRoutes";
import Header from "../components/header/Header";

import ROUTES from "../constants/routes";
import AddSunExposure from "../pages/dashboard/AddSunExposure";
import Loader from "../loader/Loader";
import Unauthorize from "../pages/unauthorize/Unauthorize";
import Recipe from "../pages/recipe/Recipe";

// userauth routing -----------
const Login = lazy(() => import("../pages/userAuth/Login"));
const Profile = lazy(() => import("../pages/profile/Profile"));
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Events = lazy(() => import("../pages/events/Events"));
const Meals = lazy(() => import("../pages/meals/Meals"));
const Labs = lazy(() => import("../pages/labs/Labs"));
const Activities = lazy(() => import("../pages/activities/Activities"));
const Measurements = lazy(() => import("../pages/bristol/Bristols"));
const Subjective = lazy(() => import("../pages/subjective/Subjective"));
const Help = lazy(() => import("../pages/help/Help"));
const Notification = lazy(() => import("../pages/notification/Notification"));

// screens routing ------------

interface Props {
  path: string;
  component: React.ComponentType;
  routeProps?: RouteComponentProps;
  isProtected?: boolean;
}

/**
 * Display specific components with Header and Bottom Navbar
 * @param props route path and component
 * @returns component wrappped with Header and BottomNavbar
 */
const WithNavbar: React.FC<Props> = ({
  path,
  component,
  routeProps,
  isProtected = true,
}) => {
  return (
    <>
      {isProtected ? (
        <PrivateGuard {...{ path, component, ...routeProps }} />
      ) : (
        <Route path={path} component={component} exact />
      )}
    </>
  );
};

const PublicRoutes: FC<RouteComponentProps> = (props: RouteComponentProps) => (
  <IonPage id="main">
    {window.location.pathname !== "/login" ? <Header /> : ""}
    <Suspense fallback={<Loader />}>
      <Switch>
        <PrivateGuard path={ROUTES.ADDSUNEXPOSURE} component={AddSunExposure} />
        <WithNavbar path={ROUTES.HELP} component={Help} routeProps={props} />

        <WithNavbar
          path={ROUTES.SUBJECTIVE}
          component={Subjective}
          routeProps={props}
        />

        <WithNavbar
          path={ROUTES.MEASUREMENTS}
          component={Measurements}
          routeProps={props}
        />
        <WithNavbar path={ROUTES.LABS} component={Labs} routeProps={props} />
        <WithNavbar
          path={ROUTES.PROFILE}
          component={Profile}
          routeProps={props}
        />

        <WithNavbar path={ROUTES.MEALS} component={Meals} routeProps={props} />
        <WithNavbar
          path={ROUTES.RECIPE}
          component={Recipe}
          routeProps={props}
        />
        <WithNavbar
          path={ROUTES.DASHBOARD}
          component={Dashboard}
          routeProps={props}
        />
        <WithNavbar
          path={ROUTES.NOTIFICATION}
          component={Notification}
          routeProps={props}
        />
        <PrivateGuard path={ROUTES.LOGIN} component={Login} />
        <PrivateGuard path={ROUTES.EVENTS} component={Events} />
        <PrivateGuard path={ROUTES.ACTIVITIES} component={Activities} />
        <PrivateGuard path="*" component={Unauthorize} />
      </Switch>
    </Suspense>
  </IonPage>
);

export default PublicRoutes;
