// Import the functions you need from the SDKs you need
// eslint-disable-next-line import/no-extraneous-dependencies
import { initializeApp } from "@firebase/app";
// eslint-disable-next-line import/no-extraneous-dependencies
import { getMessaging } from "@firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const isFirebaseSupported = () => {
  if ("serviceWorker" in navigator) {
    return true;
  }
  return false;
};

const firebaseIntialize = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyALLCJPA7PGrmWsAp_h_quDxozs_02Ylzo",
    authDomain: "solarmaster-notification.firebaseapp.com",
    projectId: "solarmaster-notification",
    storageBucket: "solarmaster-notification.appspot.com",
    messagingSenderId: "811591006689",
    appId: "1:811591006689:web:47ea1fd9be1d47af087aa0",
  };
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  return messaging;
};

export { firebaseIntialize, isFirebaseSupported };
